.hero {
  background-image: url("../../Assets/Images/png/heroBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 640px) {
  .hero {
    background-image: url("../../Assets/Images/png/bgmobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
