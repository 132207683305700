.active::before {
   position: absolute;
   content: "";
   width: 3px;
   height: 100%;
   background: #0071E3;
   left: 0;
   top: 0;
   z-index: 0;
   border-radius: 8px;
}

.track::after {
   position: absolute;
   content: "";
   width: 3px;
   height: 100%;
   background: #F5F5F5;
   left: 0;
   top: 0;
   z-index: -1;
}